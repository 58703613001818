import React from "react";
import { Row, Col, Form, FormGroup, Input, Label, Button, FormFeedback, Spinner } from "reactstrap";
import { Formik, Field } from "formik";

import InputMask from "react-input-mask";
import { paymentFormValidation } from "../paymentFormValidation";
import CvvToolTip from "../components/CvvToolTip";

class PaymentForm extends React.Component {

  handlesubmit = (values, actions) => {
    console.log(values);
  };

  renderYear = () => {
    const myarr = [];
    for (let index = 2020; index <= 2030; index++) {
      myarr.push(
        <option key={index} value={index + ""}>
          {index}
        </option>
      );
    }
    return myarr;
  };

  renderMonth = () => {
    const myarr = [];
    for (let index = 1; index <= 12; index++) {
      myarr.push(
        <option key={index} value={index < 10 ? "0" + index : index + ""}>
          {index < 10 ? "0" + index : index + ""}
        </option>
      );
    }
    return myarr;
  };

  render() {
    const { defaultAmount } = this.props;
    return (
      <div className="form-div" style={{ padding: "15% 20% 0 20%" }}>
        <div style={{ marginBottom: "16%" }}>
          <h2 style={{ filter: "opacity(70%)", textAlign: "center", fontSize: "50px" }}>
            {defaultAmount} <span style={{ filter: "opacity(50%)" }}>TL</span>
          </h2>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            mobileNumber: "",
            holderName: "",
            cardNumber: "",
            expDateMonth: "",
            expDateYear: "",
            cvv: "",
          }}
          onSubmit={this.handlesubmit}
          validationSchema={paymentFormValidation}
        >
          {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
            <Form>
              <div>
                <Label style={{ filter: "opacity(50%)" }} >
                  Telefon
                </Label>
                <Input
                  bsSize="lg"
                  type="number"
                  name="mobileNumber"
                  id="mobileNumber"
                  onChange={handleChange}
                  invalid={errors.mobileNumber && touched.mobileNumber ? true : false}
                />
                <FormFeedback>{errors.mobileNumber}</FormFeedback>
              </div>
              <div style={{ marginTop: "5%" }}>
                <div style={{ backgroundColor: "#ECEBEB", padding: "3% 5%", borderRadius: "20px" }}>
                  <Label style={{ filter: "opacity(50%)" }} >
                    Kart Üzerindeki İsim
                  </Label>
                  <Input
                    bsSize="lg"
                    type="text"
                    name="holderName"
                    id="holderName"
                    onChange={handleChange}
                    invalid={errors.holderName && touched.holderName ? true : false}
                  />

                  <FormFeedback>{errors.holderName}</FormFeedback>
                  <Label style={{ filter: "opacity(50%)" ,marginTop:'15px'}} >
                    Kart Numarası
                  </Label>
                  <InputMask mask="9999-9999-9999-9999" name="cardNumber" onChange={handleChange}>
                    <Input bsSize="lg" invalid={errors.cardNumber && touched.cardNumber ? true : false} />
                  </InputMask>
                  <FormFeedback>{errors.cardNumber}</FormFeedback>
                  <Row form style={{marginTop:'15px'}}>
                    <Col md={6}>
                      <FormGroup>
                        <Label style={{ filter: "opacity(50%)" }}>
                          Son Kullanma Tarihi
                        </Label>

                        <div style={{ display: "flex" }}>
                          
                        <div>
                            <Field name="expDateYear">
                              {({ field, form, meta }) => (
                                <div>
                                  <select  className={meta.touched && meta.error?"select-date error-border":"select-date"} type="text" {...field} >
                                    <option value={""} defaultValue={""}></option>
                                    {this.renderYear()}
                                  </select>
                                  {meta.touched && meta.error && (
                                    <div className="select-error">
                                      {meta.error}
                                    </div>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                          
                          <Field name="expDateMonth">

                            {({ field, form, meta }) => (
                              <div style={{ marginRight: "10px" }}>
                                <select  className={meta.touched && meta.error?"select-date error-border":"select-date"} type="text" {...field} >
                                <option value={""} defaultValue={""}></option>
                                  {this.renderMonth()}
                                </select>
                                {meta.touched && meta.error && (
                                  <div className="select-error">
                                    {meta.error}
                                  </div>
                                )}
                              </div>
                            )}
                          </Field>
                         
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label style={{ filter: "opacity(50%)" }} >
                         Güvenlik Kodu (CVV) <CvvToolTip/>
                        
                        </Label>
                        <Input
                          bsSize="lg"
                          type="text"
                          maxLength="3"
                          name="cvv"
                          id="cvv"
                          onChange={handleChange}
                          invalid={errors.cvv && touched.cvv ? true : false}
                        />
                        <FormFeedback>{errors.cvv}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <Button
                  style={{ borderRadius: "20px", marginTop: "15%", padding: "15px" }}
                  onClick={handleSubmit}
                  block
                  color="primary"
                  size="lg"
                  disabled={isSubmitting ? true : false}
                >
                  {isSubmitting ? <Spinner size="lg" color="white" /> : "Ödeme Yap"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default PaymentForm;
