import React from "react";
import { Spinner } from "reactstrap";
const FullPageLoading = () => {
  return (
    <div className="full-page-loading">
      <Spinner style={{ width: "10rem", height: "10rem" }} color="primary" />
    </div>
  );
};

export default FullPageLoading;
