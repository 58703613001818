import * as Yup from "yup";

export const paymentFormValidation = Yup.object().shape({
  mobileNumber: Yup.number()
    .required("Gerekli.")
    .moreThan(999999999,'Lütfen geçerli bir numara giriniz.').lessThan(10000000000,'Lütfen geçerli bir numaray giriniz.'),
    holderName:  Yup.string().required("Gerekli").matches(new RegExp(/^[a-zA-ZğüşöçİĞÜŞÖÇ\s]*$/),"Lütfen geçerli bir isim girin."),
    cardNumber: Yup.string().required("Gerekli.").matches(new RegExp("[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}"),"Lütfen geçerli bir kredi kartı numarası girin."),
    expDateMonth: Yup.string().required("Gerekli.").max(2,'Lütfen geçerli bir ay giriniz.'),
    expDateYear: Yup.string().required("Gerekli.").max(4,'Lütfen geçerli bir yıl giriniz.'),
    cvv: Yup.string().required("Gerekli.").length(3,'Lütfen geçerli bir CVV kodu giriniz.').matches(new RegExp('^[0-9]*$'),"Lütfen geçerli bir CVV kodu giriniz."),
});
