import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NotFound from "./pages/404";
import PaymentPage from "./pages/PaymentPage";

class App extends React.Component {
  render() {
   return( <Router>
      <Switch>
        <Route path="/" exact component={PaymentPage}/>
        <Route path="/404" exact component={NotFound}/>
        <Route path="*" exact component={NotFound}/>
      </Switch>
    </Router>)
  }
}

export default App;
