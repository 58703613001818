import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const CvvToolTip = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
  <>
      <span style={{textDecoration: "underline",fontWeight:'bold'}} href="#" id="cvvtooltip">?</span>
      <Tooltip placement="right" isOpen={tooltipOpen} target="cvvtooltip" toggle={toggle}>
       Kartınızın arka yüzünde bulunan 3 haneli sayı.
      </Tooltip>
    </>
  );
}

export default CvvToolTip;