import React from "react";
import { Row, Col } from "reactstrap";
import axios from "axios";

import playstore from "../assets/images/playstore.png";
import applestore from "../assets/images/applestore.png";
import { getQR } from "../apis/qr";
import PaymentForm from "../forms/PaymentForm";
import FullPageLoading from "../components/FullPageLoading";

class PaymentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      merchant: null,
      defaultAmount: null,
    };
  }

  componentDidMount = async () => {
    const qr = new URLSearchParams(this.props.location.search).get("qrcode");
    if (qr) {
      try {
        const response = await axios(getQR(qr));
        const { merchant, defaultAmount } = response.data.data;
        this.setState({ merchant, defaultAmount });
      } catch (error) {
        this.props.history.push('/404',null)
      }
    } else {
      this.props.history.push('/404',null);
    }
  };

  render() {
    const { merchant, defaultAmount } = this.state;
    return merchant !== null && defaultAmount !== null ? (
      <Row>
        <Col xs="12" md="5" lg="5" style={{ backgroundColor: "#ECEBEB" }}>
          <div
            className="left-container-list"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
              paddingTop: "20%",
            }}
          >
            <div style={{ marginBottom: "5%", textAlign: "center" }}>
              <img className="brand-logo" src={merchant?.logo} style={{ width: "auto", height: "200px" }} alt="logo" />
            </div>
            <div className="place-div" style={{ textAlign: "center" }}>
              <h2 className="brand-text" style={{ color: "#A9A8A8" }}>
                {merchant.name}
              </h2>
              <h3 className="place-text" style={{ color: "#A9A8A8" }}>
                {merchant.district}
              </h3>
            </div>
            <div className="app-market-logo" style={{ width: "170px", marginTop: "50%" }}>
              <img src={applestore} style={{ height: "70px", width: "70px", filter: "opacity(50%)", marginRight: "30px" }} alt="applestore" />
              <img src={playstore} style={{ height: "70px", width: "70px", filter: "opacity(50%) " }} alt="googlestore" />
            </div>
          </div>
        </Col>

        <Col xs="12" md="7" lg="7">
          <PaymentForm defaultAmount={defaultAmount} />
        </Col>
      </Row>
    ) : (
      <FullPageLoading />
    );
  }
}

export default PaymentPage;
